.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Firefox */
.hide-scrollbar {
  scrollbar-width: none;
}

/* IE, Edge, and Chrome */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Make sure scrolling still works when the scrollbar is hidden */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  overflow: auto;  /* For all other browsers */
}

.ant-table-wrapper {
  height: calc(100vh - 200px);
}

.drag-over {
  background-color: #e6f7ff !important;
}

.chat-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff; /* Make background white */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f0f2f5; /* Lighter background for the chat area */
}

.user-message {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  margin-bottom: 8px;
}

.assistant-message {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-end !important;
  padding: 8px;
  margin-bottom: 8px;
}

/* Shared message bubble styling */
.user-message div, .assistant-message div {
  max-width: 60%;
  padding: 12px 16px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.5;
}

.user-message div {
  background-color: #1890ff; /* Blue background for user's message */
  color: #fff; /* White text for readability */
}

.assistant-message div {
  background-color: #ffffff; /* White background for assistant's message */
  color: #595959; /* Darker text for contrast */
  border: 1px solid #e6e6e6; /* Border for assistant's message bubble */
}

/* Chat input styling */
.chat-input {
  display: flex;
  padding: 16px;
  background-color: #ffffff; /* Match the top input area with the bottom */
  border-top: 1px solid #e8e8e8;
}

.chat-input .ant-input {
  margin-right: 8px;
  flex-grow: 1; /* Allow input to grow and fill space */
}

/* Spin / Loading styling */
.chat-loading {
  display: flex;
  align-items: center; /* Center loading spinner vertically */
  justify-content: center;
  padding: 16px;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #e6e6e6aa;
}

body, html, #root {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
}

.ant-notification {
  top: 60px;
}