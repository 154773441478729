/* src/pages/SignIn.css */
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.signin-card {
  width: 350px;
  padding: 40px 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: #ffffff;
}

.signin-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}